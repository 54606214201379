import axios from "axios";

import * as actionTypes from "./types";

import * as pageActions from "../page/actions";

import * as toastActions from "../../components/common/toastify";

import { BACKEND_SERVER_URL } from "../../config";
import { setDefaultHeader } from "../../utils/setDefaultHeader";

export const getVARLocDetailList = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());

  axios
    .post(BACKEND_SERVER_URL + "/getVARLocDetailList", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_VAR_DETAIL_LOCATION_LIST,
          payload: data.varDetailList,
        });
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const getLocationsByUserId = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  console.log(reqPacket);
  axios
    .post(BACKEND_SERVER_URL + "/getLocationsByUserId", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      console.log(data);
      console.log(resultCode);
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_LOCATION_LIST_BY_USER_ID,
          payload: data.locationList,
        });
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};
export const getLocationByCity = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/getLocationByCity", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_LOCATION_LIST_BY_CITY,
          payload: data.locationList,
        });
      } else if (resultCode === "ERROR-003") {
        const NullData = [];
        dispatch({
          type: actionTypes.SET_LOCATION_LIST_BY_CITY,
          payload: NullData,
        });
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};
export const addToCart = (reqPacket, userId) => (dispatch) => {
  dispatch(pageActions.setPageLoading());

  axios
    .post(BACKEND_SERVER_URL + "/addToCart", reqPacket)
    .then((res) => {
      const { resultCode } = res.data;
      if (resultCode === "200") {
        toastActions.Success(
          "Successfully added!. click on cart icon to further proceed."
        );
        dispatch(cartView(userId));
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const cartView = (userId) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  setDefaultHeader(true);
  axios
    .get(BACKEND_SERVER_URL + "/cartView/" + userId)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_CART_ITEM_LIST,
          payload: data,
        });
        // eslint-disable-next-line no-empty
      } else if (resultCode === "ERROR-003") {
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const deleteCartItem = (reqPacket) => (dispatch) => {

  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/deleteCartItem", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_CART_ITEM_LIST,
          payload: data,
        });
        toastActions.Success("Successfully removed from cart!");
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const getLocationDetail = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  console.log(reqPacket);
  axios
    .post(BACKEND_SERVER_URL + "/getLocationDetail", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;

      console.log(res.data);
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_LOCATION_DETAIL,
          payload: data,
        });
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const chargeCustomerByLocation = (reqPacket, history) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/chargeCustomerByLocation", reqPacket)
    .then((res) => {
      const { resultCode, message } = res.data;
      if (resultCode === "200") {
        const addBuyerLocationsReqPacket = {
          buyerId: reqPacket.userId,
          locationId: reqPacket.locationIDs,
          paymentMethod: "Credit Card",
        };

        dispatch(addBuyerLocations(addBuyerLocationsReqPacket, history));
        window.location.reload();
      } else {
        toastActions.Error(message);
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const addBuyerLocations = (reqPacket, history) => (dispatch) => {
  dispatch(pageActions.setPageLoading());

  axios
    .post(BACKEND_SERVER_URL + "/addBuyerLocations", reqPacket)
    .then((res) => {
      const { resultCode } = res.data;
      if (resultCode === "200") {
        history.push("/");
        const data= {
          cartId: reqPacket.locationId,
          userId: reqPacket.buyerId
        }
        console.log(data)
        
        toastActions.Success("Successfully purchase!");
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const getUserLocations = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/getUserLocations", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_USER_LOCATIONS,
          payload: data.locationsList,
        });
        // eslint-disable-next-line no-empty
      } else if (resultCode === "ERROR-003") {
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};
