import axios from "axios";

import * as actionTypes from "./types";

import * as pageActions from "../page/actions";

import * as toastActions from "../../components/common/toastify";

import { BACKEND_SERVER_URL, STRIPE_KEY } from "../../config";
import { stringToBase64 } from "../../utils/common";
const qs = require("qs");

export const getCountries = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/getCountries", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      console.log(data);
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_ALL_COUNTRIES,
          payload: data.countries,
        });
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const loadAllCreditCards = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/loadAllCreditCards", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      // console.log(data);
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_CREDIT_CARD_LIST,
          payload: data.creditCardList,
        });
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const createStripeToken = (reqPacket, userId) => (dispatch) => {
  console.log(reqPacket);
  const data = qs.stringify({
    "card[number]": `${reqPacket.cardNumber}`,
    "card[exp_month]": `${reqPacket.expMonth}`,
    "card[exp_year]": `${reqPacket.expYear}`,
    "card[cvc]": `${reqPacket.cvc}`,
   
  });
  axios
    .post("https://api.stripe.com/v1/tokens", data, {
     
      headers: {
        Authorization: `Bearer ${STRIPE_KEY}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((res) => {
      console.log(res.data)
      console.log(res.data.id)
      const { status, data } = res;
      if (status === 200) {
        const card = data && data.card ? data.card : {};
        const encodedData = `${reqPacket.cardNumber}${reqPacket.expMonth}20${reqPacket.expYear}${reqPacket.cvc}`;
      console.log(encodedData, "encoded data here")
        const encoded = stringToBase64(encodedData);
        const addCardRequestPacket = {
          userId,
          brand: card && card.brand ? card.brand : "",
          last4: card && card.last4 ? card.last4 : "",
          detail: encoded,
          creditCardToken: res.data.id
        };
        dispatch(addCreditCard(addCardRequestPacket));
      } else {
        // toastActions.Error(statusText);
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const addCreditCard = (reqPacket) => (dispatch) => {
  console.log(reqPacket)
  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/addCreditCard", reqPacket)
    .then((res) => {
      console.log(res.data)
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_CREDIT_CARD_LIST,
          payload: data.creditCardList,
        });
        toastActions.Success("Successfully added card!");
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const markCreditCardDefault = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/markCreditCardDefault", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_CREDIT_CARD_LIST,
          payload: data.creditCardList,
        });
        toastActions.Success("Successfully change default card!");
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const deleteCreditCard = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/deleteCreditCard", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_CREDIT_CARD_LIST,
          payload: data.creditCardList,
        });
        toastActions.Success("Successfully delete card!");
      } else {
        // toastActions.Error(message)
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};
