import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../../../utils/common";
import { Error } from "../../../../components/common/toastify";
import axios from 'axios'
import * as toastActions from "../../../../components/common/toastify";
import { BACKEND_SERVER_URL } from "../../../../config";
const ForgotPasswordEmailForm = () => {
  const history = useHistory();
  const [emailAddress, setEmailAddress] = useState("");

  const handleSubmit =async (e) => {
    e.preventDefault();
    console.log("checked enter email");
    if (emailAddress === "" || !validateEmail(emailAddress)) {
      Error("Please enter valid email!");
    } else {
      const result=await axios.post(`${BACKEND_SERVER_URL}/sendForgotPasswordLink`,{
        emailAddress
      })
      if(result.data.resultCode==200){
        toastActions.Success("The reset password email has been sent successfully.");
      }

      // history.push("/change-password");
    }
  };

  return (
    <div>
      <div className="d-flex flex-column-fluid flex-center">
        {/* begin::Signin */}
        <div className="login-form login-signin">
          {/* begin::Form */}
          <img
            src="images/xp_eats.svg"
            alt="logo"
            className="envision-red-logo"
            width="300px"
          />
          <form
            className="login-form form fv-plugins-bootstrap fv-plugins-framework"
            noValidate="novalidate"
            id="kt_login_signin_form"
            onSubmit={handleSubmit}
          >
            <div className="pb-7 pt-lg-0 pt-5">
              <h3 className="welcome-title" style={{ textAlign: "center" }}>
                MOBILE DELIVERY FRANCHISE
              </h3>
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                type="text"
                name="username"
                autoComplete="off"
                placeholder="Enter email address"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </div>
            <div className="d-flex pb-lg-0 pb-5">
              <button
                type="submit"
                id="nextFullBtn"
                className="gilroy-medium btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
              >
                NEXT
              </button>
            </div>
          </form>
          {/* end::Form */}
        </div>
        {/* end::Signin */}
      </div>
    </div>
  );
};
export default ForgotPasswordEmailForm;
