import * as Yup from "yup";
export const registerValidationSchema = Yup.object().shape({
  imageFile: Yup.mixed()
    // .required("Image is required!")
    .test("fileType", "Invalid file format", (value) => {
      if (value) {
        return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
      }
      return true;
    })
    .test("fileSize", "File too large", (value) => {
      if (value) {
        return value.size <= 5 * 1024 * 1024; // 5MB limit
      }
      return true;
    }),
  firstName: Yup.string()
    .required("First name is required!")
    .min(2, "First name must be at least 2 characters")
    .max(15, "First name must be at most 15 characters")
    .matches(/^[A-Za-z ]*$/, "Only letters and spaces are allowed"),
  lastName: Yup.string()
    .required("Last name is required!")
    .min(2, "Last name must be at least 2 characters")
    .max(15, "Last name must be at most 15 characters")
    .matches(/^[A-Za-z ]*$/, "Only letters and spaces are allowed"),
  userName: Yup.string()
    .required("User name is required!")
    .min(3, "User name must be at least 3 characters")
    .max(15, "User name must be at most 15 characters")
    .matches(
      /^[a-zA-Z0-9_-]*$/,
      "Only alphanumeric characters, underscores, and hyphens are allowed"
    ),
  currency: Yup.string().required("Currency is required!"),
  emailId: Yup.string()
    .required("Email address  is required!")
    .email("Please enter a valid email!"),
  contactNumber: Yup.string()
    .required("Contact number is required!")
    .matches(/^\d{1,10}$/, "Contact number cannot exceed 10 digits"),
  countryId: Yup.string().required("Please select a country!"),
  password: Yup.string()
    .required("Password is required!")
    .min(8, "Password must be at least 8 characters"),
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
    //   "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
    // ),
  confirmPassword: Yup.string()
    .required("Confirm password  is required!")
    .oneOf(
      [Yup.ref("password"), null],
      "Password & Confirm Password must match"
    ),
});

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required!")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm password  is required!")
    .oneOf(
      [Yup.ref("password"), null],
      "Password & Confirm Password must match"
    ),
});
