import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Error } from "../../../../components/common/toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { BACKEND_SERVER_URL } from "../../../../config";
import * as toastActions from "../../../../components/common/toastify";
import { changePasswordSchema } from "../../../../utils/YupSchema";
import { useFormik } from "formik";
const initialValues = {
  password: "",
  confirmPassword: "",
};
const ChangePassForm = () => {
  const history = useHistory();
  const { userId } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showInputPassword, setShowInputPassword2] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordInput, setInputPassword] = useState("");
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: async (values, action) => {
      console.log("--------------");
      const requestPacket = {
        userId,
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
      };
      try {
        const result = await axios.post(
          `${BACKEND_SERVER_URL}/forgotPassword`,
          requestPacket
        );
        console.log(result);
        if (result.data.resultCode == 200) {
          toastActions.Success(
            "Your password has been successfully changed. You can now log in."
          );
          history.push("/login")
        }
      } catch (error) {
        toastActions.Error("Server error.Please try again");
      }
    },
  });
  // const handleSubmit = () => {
  //   if (password === "" || passwordInput === "") {
  //     Error("Enter Password!");
  //   } else
  //     password === passwordInput
  //       ? handleReset()
  //       : Error("Password Doesn't Match!");
  // };

  return (
    <div>
      <div className="d-flex flex-column-fluid flex-center">
        <div className="login-form login-signin">
          {/* begin::Form */}
          <img
            src="images/xp_eats.svg"
            alt="logo"
            className="envision-red-logo"
            width="300px"
          />
          <form
            onSubmit={handleSubmit}
            className="login-form form fv-plugins-bootstrap fv-plugins-framework"
            noValidate="novalidate"
            id="kt_login_signin_form"
          >
            <div className="pb-7 pt-lg-0 pt-5">
              <h3 className="welcome-title" style={{ textAlign: "center" }}>
                MOBILE DELIVERY FRANCHISE
              </h3>
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                // name="confirmPassword"
                autoComplete="off"
                placeholder="Enter Password"
                // value={password}
                // onChange={(e) => setPassword(e.target.value)}
              />
              <span
                style={{ cursor: "pointer" }}
                className="show"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "HIDE" : "SHOW"}
              </span>
              {errors.password && touched.password ? (
                <p
                  className="validationError"
                  style={{ fontSize: "13px", fontWeight: 500, color: "red" }}
                >
                  <span class="font-medium">*{errors.password}</span>
                </p>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                id="confirmPassword"
                name="confirmPassword"
                type={showInputPassword ? "text" : "password"}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                placeholder="Enter Confirm Password"
                // value={passwordInput}
                // onChange={(e) => setInputPassword(e.target.value)}
              />

              <span
                style={{ cursor: "pointer" }}
                className="show"
                onClick={() => setShowInputPassword2(!showInputPassword)}
              >
                {showInputPassword ? "HIDE" : "SHOW"}
              </span>
              {errors.confirmPassword && touched.confirmPassword ? (
                <p className="validationError"  style={{ fontSize: "13px", fontWeight: 500, color: "red" }}>
                  <span class="font-medium">*{errors.confirmPassword}</span>
                </p>
              ) : null}
            </div>
            <div className="d-flex pb-lg-0 pb-5">
              <button
                type="button"
                id="nextFullBtn"
                className="gilroy-medium btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                onClick={handleSubmit}
              >
                SUBMIT
              </button>
            </div>
          </form>
          {/* end::Form */}
        </div>
      </div>

      <div className="footer-links">
        <a href="#">Terms of Service</a>
        <a href="#">Privacy Policy</a>
        <a href="#">Contact Us</a>
      </div>
    </div>
  );
};

export default ChangePassForm;
