import React, { useState } from "react";
import "./App.css";
import { withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import PrivateRoute from "./components/common/PrivateRoute";
import PublicRoute from "./components/common/PublicRoute";
import { logoutUser, setCurrentUser } from "./store/auth/actions";
import Auth from "./pages/Auth";
import Index from "./pages";
import axios from "axios";

const App = ({ onSetCurrentUser, onLogoutUser }) => {
  console.log(onSetCurrentUser);
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

  const [publicRoutes] = useState([
    "/login",
    "/forgot-password-email",
    "/change-password",
    "/register",
    // "/success",
    // "/failed"
  ]);
  const [privateRoutes] = useState([
    "/",
    "/list-view",
    "/admin-location-grid-view-:userId",
    "/admin-location-grid-view",
    "/admin-location-details-:id",
    "/manage-location-details-:id",
    "/location-status",
    "/profile",
    "/checkout-two-:userId",
    "/checkout",
    "/success",
    "/failed"
    
  ]);

  if (localStorage.jwtTokenUser) {
    onSetCurrentUser(JSON.parse(localStorage.jwtTokenUser));
  } else {
    onLogoutUser();
  }
  return (
    <>
      <Switch>
        {/* Start: Public Routes */}
        {publicRoutes.map((route, idx) => (
       
          <PublicRoute exact key={idx} path={`${route}`} component={Auth} />
        ))}
        {/* End: Public Routes */}

        {/* Start: Public Routes */}
        {privateRoutes.map((route, idx) => (
         
          <PrivateRoute exact key={idx} path={`${route}`} component={Index} />
        ))}
        <Redirect to="/" />
      </Switch>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetCurrentUser: (user) => dispatch(setCurrentUser(user)),
    onLogoutUser: () => dispatch(logoutUser()),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(App));
