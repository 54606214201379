import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getUserLocations } from "../../store/location/actions";
import {
  loadAllCreditCards,
  deleteCreditCard,
} from "../../store/common/actions";
import Loader from "../../components/common/Loader";
import Profile from "./profile";
import Payment from "./payment";
import { getProfileData } from "../../store/auth/actions";
import Product from "./product";

export default function Index() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) =>state.auth);
  // console.log(user);
  const { loading } = useSelector((state) => state.page);
  const { userLocationList } = useSelector((state) => state.location);
  const { creditCardList } = useSelector((state) => state.common);

  useEffect(() => {
    const requestPacket = {
      buyerId: `${user && user.userId ? user.userId : ""}`,
      ownerId: "",
      adminId: "",
    };
    dispatch(getUserLocations(requestPacket));

    const reqPacket = {
      userId: user.userId,
    };
    dispatch(loadAllCreditCards(reqPacket));
  }, []);

  const deleteCardHandler = (card) => {
    const reqPacket = {
      cardId: card.cardId,
      stripeCardId: card.stripeCardId,
      userId: user.userId,
    };
    dispatch(deleteCreditCard(reqPacket));
  };
  useEffect(() => {
    const reqPacket = {
      userId: `${user.userId}`,
      userType: "user",
    };
    dispatch(getProfileData(reqPacket));
  }, []);
  return (
    <div className="page11-custom">
      {loading ? (
        <Loader />
      ) : (
        <div className="profile-page11">
          {/* Start Product Section */}
          <Product locationList={userLocationList} />
          {/* End Product Section */}

          <div className="profile-section">
            {/* Start Profile Section */}
            <Profile user={user} />
            {/* End Profile Section */}

            {/* Start Payment Section */}
            {/* <Payment
              creditCardList={creditCardList}
              deleteCardHandler={deleteCardHandler}
            /> */}
            {/* End Payment Section */}
          </div>
        </div>
      )}
    </div>
  );
};
