import React from "react";
import RegisterForm from "./components/registerForm";

export default function Index() {
  return (
    <div className="page7-custom">
      {/* start Register Form */}
      <RegisterForm />
      {/* End Register Form */}
    </div>
  );
};
