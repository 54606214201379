import React from 'react'
import { useHistory } from "react-router-dom";
const Failed = () => {
  const history = useHistory();
  const handleClick=()=>{

    history.push("/");

  }
  return (
    <> <div className="vh-100 d-flex justify-content-center align-items-center">
    <div>
      <div className="mb-4 text-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" fill="currentColor" class="bi bi-file-earmark-x" viewBox="0 0 16 16"> <path d="M6.854 7.146a.5.5 0 1 0-.708.708L7.293 9l-1.147 1.146a.5.5 0 0 0 .708.708L8 9.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 9l1.147-1.146a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146z"/> <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/> </svg>
      </div>
      <div className="text-center">
        <h1>Payment Failed !!!</h1>
        {/* <p>We've send the link to your inbox. Lorem ipsum dolor sit, </p> */}
        <button className="btn btn-danger" onClick={handleClick}>Back Home</button>
      </div>
    </div>
  </div></>
  )
}
 
export default Failed