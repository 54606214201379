import * as actionTypes from "./types";

const initialState = {
  countries: [],
  creditCardList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ALL_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case actionTypes.SET_CREDIT_CARD_LIST:
      return {
        ...state,
        creditCardList: action.payload,
      };
    default:
      return state;
  }
}
