import React from "react";
import { Link } from "react-router-dom";

const singleLocation = ({
  locationTitle,
  locationDetails,
  locationPrice,
  locationImage,
  locationDistance,
  locationId,
  locationTax,
  addItemToCart,
  removeItemFromCart,
  locationThirdpartyId,
  user,
  isAddedIntoCard,
}) => {
  const handleChange = (e) => {
    if (e.target.checked) {
      addItemToCart({
        itemId: locationId,
        itemName: locationTitle,
        itemAddress: locationDetails,
        itemImage: locationImage,
        itemPrice: locationPrice,
        itemTax: locationTax,
        itemRange: locationDistance,
        itemThirdpartyId: locationThirdpartyId,
        userId: user.userId,
      });
    } else {
      removeItemFromCart(locationId);
    }
  };
  return (
    <div className="position-relative">
      <div
        className="action-container position-absolute"
        style={{ width: "100%" }}
      >
        <input
          type="checkbox"
          name="checkbox"
          defaultChecked={isAddedIntoCard(locationId)}
          onChange={handleChange}
        />
      </div>
      <img
        style={{
          width: "-webkit-fill-available",
          objectFit: "cover",
          height: "200px",
        }}
        src={locationImage}
      />
      <Link to={`/admin-location-details-${locationId}`}>
        <div className="location-explaination">
          <div className="location-desc">
            <div
              className="loc-title"
              style={{
                color: "black",
              }}
            >
              {locationTitle?.substring(0, 25)}
            </div>
            <div
              className="location-price"
              style={{
                color: "black",
              }}
            >{`$${locationPrice}`}</div>
          </div>
          <div
            className="location-details"
            style={{
              color: "black",
            }}
          >
            {locationDetails?.substring(0, 25)}
            <div className="location-distance"> {`${locationDistance} km`}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default singleLocation;
