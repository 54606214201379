import axios from "axios";

import * as actionTypes from "./types";

import * as pageActions from "../page/actions";

import * as toastActions from "../../components/common/toastify";

import { BACKEND_SERVER_URL, BACKEND_SERVER_URL_USER_EARNINGS } from "../../config";
import { setDefaultHeader } from "../../utils";

// Set logged in user (Verified)
export const setCurrentUser = (decoded) => {
  // console.log(decoded);
  // console.log(JSON.parse(localStorage.jwtTokenUser));
  // console.log("localStorage", localStorage, decoded);
  if (!localStorage.jwtTokenUser) {
    localStorage.setItem("jwtTokenUser", JSON.stringify(decoded));
  }
  return {
    type: actionTypes.SET_CURRENT_USER,
    payload: decoded,
  };
};

export const clearCurrentUser = () => {
  return {
    type: actionTypes.CLEAR_CURRENT_USER,
  };
};
// Set logged in user (Verified)
export const showFailedModal = (data) => {

  return {
    type: actionTypes.SHOW_MODAL,
    payload: data,
  };
};
// Set logged in user (Verified)
export const closeFailedModal = (data) => {

  return {
    type: actionTypes.CLOSE_SHOW_MODAL,
    payload: data,
  };
};
// Log user out (Verified)
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("jwtTokenUser");
  dispatch(clearCurrentUser());
};

// registerUser
export const registerUser =  (reqPacket, history) => async(dispatch) => {
  dispatch(pageActions.setPageLoading());
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post(BACKEND_SERVER_URL + "/registerUser", reqPacket, config);
    const { resultCode,message } = res.data;
    if (resultCode === "200") {
      toastActions.Success("Congratulations! Your account successfully registered.");
      history.push("/login");
    } else {
      toastActions.Error(message)
    }
  } catch (err) {
    toastActions.Error("Something went wrong! please try again.")
  } finally {
    dispatch(pageActions.clearPageLoading());
  }
  
};

// Login
export const login = (reqPacket, history) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + "/login", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      console.log(resultCode);
      console.log(data);
      if (resultCode === "200") {
        dispatch(setCurrentUser(data));
        history.push(`/`);
        toastActions.Success("Successfully logged in!.");
      } else {
        dispatch(showFailedModal(true));
        // toastActions.Error("Invalid Credentials")
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};

export const getProfileData = (reqPacket) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  setDefaultHeader(true);
  axios
    .post(BACKEND_SERVER_URL + "/getProfileData", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      // console.log(data);
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_PROFILE_DATA,
          payload: data,
        });
      } else {
        dispatch(logoutUser());
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};


export const getUserTransaction = (id) => (dispatch) => {
  dispatch(pageActions.setPageLoading());
  // setDefaultHeader(true);
  // .get(BACKEND_SERVER_URL_USER_EARNINGS + `?command=my_account&currency=USD&user_id=${id}`)
  axios
    .get(BACKEND_SERVER_URL_USER_EARNINGS + `command=my_buyin_account_earning&location_id=876&buyin_user_id=${id}`)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: actionTypes.SET_TRANSACTION_DATA,
          payload: data,
        });
      } else {
        dispatch(logoutUser());
      }
    })
    .catch((err) => {
      console.log("checking err: ", err);
      // toastActions.Error("Something went wrong! please try again.")
    })
    .finally(() => dispatch(pageActions.clearPageLoading()));
};
