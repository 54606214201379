import React from "react";

export default ({ user }) => {
  // console.log(user);
  return (
    <div>
      <div className="profile-info">
        <div className="top-part ">Profile Info</div>
        {user && (
          <>
            <div className="info-details">
              <div className="name fw-bold">Full Name:</div>
              <div className="e-name">
                {user.firstName} {user.lastName}
              </div>
            </div>
            {user.userName && (
              <div className="info-details ">
                <div className="name fw-bold">UserName:</div>
                <div className="e-name">{user.userName}</div>
              </div>
            )}
            <div className="info-details">
              <div className="name fw-bold">Email:</div>
              <div className="e-name">{user.email}</div>
            </div>
            <div className="info-details">
              <div className="name fw-bold">Phone:</div>
              <div className="e-name">{user.contactNumber}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
