import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addBuyerLocations, deleteCartItem } from "../store/location/actions";
import { useEffect } from "react";
const Success = () => {
  const history = useHistory();
  const data = localStorage.getItem("cartData");

  let itemId;
  let userId;
  let cartId;
  
  if (data) {
    // Parse the data from a string to a JavaScript object
    const cartData = JSON.parse(data);

    console.log(cartData[0])
  
    // Access the first item's itemId and userId
    const firstItem = cartData[0];
    itemId = firstItem?.itemId;
    userId = firstItem?.userId;
    cartId= firstItem?.cartId
  
    console.log("itemId: " + itemId);
    console.log("xartId: " + cartId);
    console.log("userId: " + userId);
  } else {
    // console.log("No data found in localStorage for 'cartData'");
   history.push("/")
  }
  
  // Now you can use itemId and userId in your reqPacket
  const dispatch = useDispatch();
  
  const reqPacket = {
    locationId:itemId,
    buyerId:userId,
    paymentMethod: "Credit Card",
  };
  useEffect(()=>{

    
    dispatch(addBuyerLocations(reqPacket))
  },[])
  // Use reqPacket as needed in your code
  
console.log(reqPacket)
  const handleClick=()=>{
    const reqPacket = {
      cartId ,
      userId,
    };
    console.log(reqPacket)
    dispatch(deleteCartItem(reqPacket));
    history.push("/location-status");


  }
  return (
    <>
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div>
          <div className="mb-4 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-success bi bi-check-circle"
              width="75"
              height="75"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <div className="text-center">
            <h1>Thank You !</h1>
            {/* <p>We've send the link to your inbox. Lorem ipsum dolor sit, </p> */}
             <button className="btn btn-success" onClick={handleClick}>Back Home</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
