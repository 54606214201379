import React from "react";
import { Route } from "react-router-dom";
import Register from "./Register";
import Login from "./Login";
import ChangePassword from "./ChangePassword";
import ForgotPasswordEmail from "./ForgotPasswordEmail";
import Success from "../../components/Success";
import Failed from "../../components/Failed";

export default function Index() {
  return (
    <React.Fragment>
      <Route exact path="/register" component={Register} />
      <Route exact path="/login" component={Login} />
      {/* <Route exact path="/success" component={Success} />
      <Route exact path="/failed" component={Failed} /> */}
      <Route exact path="/change-password" component={ChangePassword} />
      <Route
        exact
        path="/forgot-password-email"
        component={ForgotPasswordEmail}
      />
    </React.Fragment>
  );
};
