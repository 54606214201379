import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutForm";
const stripePromise = loadStripe(
  "pk_test_51IpgPFFiaskO88zbTqPAKhL0hyZ4s7b88yd30hlAyp2in2H76C5PgT3mPUYls5dxN3aW8FQEuDbbjp8f43mjMZfE00z2BzMLnQ"
);
const Index = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};
export default Index;
