import React from "react";
import { Route } from "react-router-dom";

import AdminLocationListView from "./AdminLocationListView";
import AdminLocationGridView from "./AdminLocationGridView";
import AdminLocationDetails from "./AdminLocationDetails/index";
import ManageLocationDetails from "./AdminLocationDetails/index1";
import AdminLocationStatus from "./AdminLocationStatus";
import UserProfile from "./UserProfile/index";
import NewCheckOut from "./NewCheckOut";
import Payment from "./payment";
import Success from "../components/Success";
import Failed from "../components/Failed";


export default function Index() {
  return (
    <React.Fragment>
      <Route exact path="/" component={AdminLocationListView} />
      <Route exact path="/list-view" component={AdminLocationListView} />
      <Route
        exact
        path="/admin-location-grid-view-:userId"
        component={AdminLocationGridView}
      />
      <Route
        exact
        path="/admin-location-grid-view"
        component={AdminLocationGridView}
      />
      <Route
        exact
        path="/admin-location-details-:id"
        component={AdminLocationDetails}
      />
      <Route
        exact
        path="/manage-location-details-:id"
        component={ManageLocationDetails}
      />

      <Route exact path="/location-status" component={AdminLocationStatus} />
      <Route exact path="/profile" component={UserProfile} />
      <Route exact path="/success" component={Success} />
      <Route exact path="/failed" component={Failed} />
     
      <Route exact path="/checkout-two-:userId" component={NewCheckOut} />
      <Route exact path="/checkout" component={Payment} />
    </React.Fragment>
  );
}
