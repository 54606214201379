import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import { Marker } from "../AdminLocationListView/components/googleMap/singleMarker";
import { addToCart, getLocationDetail } from "../../store/location/actions";
import { Success } from "../../components/common/toastify";
import _ from "lodash";

const defaultProps = {
  zoom: 16,
};

const Index = (props) => {
  const { id } = props.match.params;
  const dispatch = useDispatch();
  const { locationDetail, cartItemsList } = useSelector(
    (state) => state.location
  );
  console.log(locationDetail)
  const { user } = useSelector((state) => state.auth);

  const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
  useEffect(() => {
    const reqPacket = {
      locationId: id,
    };
    dispatch(getLocationDetail(reqPacket));
  }, []);

  const onAddToCard = () => {
    const index = _.findIndex(
      cartItemsList.cartItemsList,
      (obj) => obj.itemId === locationDetail.locationId
    );
    if (index >= 0) {
      Success("Already Added!");
    } else {
      const cartItem = {
        itemId: locationDetail.locationId,
        itemName: locationDetail.title,
        itemAddress: locationDetail.description,
        itemImage: locationDetail.image,
        itemPrice: locationDetail.tabletFee,
        itemTax: locationDetail.tax,
        itemRange: locationDetail.kmRange,
        itemThirdpartyId: locationDetail.locationThirdpartyId,
        userId: user.userId,
      };
      const reqPacket = {
        itemList: [cartItem],
      };
      dispatch(addToCart(reqPacket, user.userId));
    }
  };

  const lat = Number(locationDetail.latitude);
  const lng = Number(locationDetail.longitude);
  return (
    <div className="page6-custom">
      <div className="page-6-container">
        <div className="home-top-section mb-2">
          <div className="home-t">{locationDetail && locationDetail.title}</div>
          <div className="home-price">
            <span style={{ flex: "2", whiteSpace: "nowrap" }} className="price">
              $ {locationDetail && locationDetail.tabletFee} CAD
            </span>
          </div>
          {/* <div className="d-flex justify-content-between align-items-center">
            <h1 className="home-t">{locationDetail && locationDetail.title}</h1>
            <p>$ {locationDetail && locationDetail.tabletFee} CAD</p>
          </div> */}
        </div>
        <div className="home-address-details">
          <div className="home-address-desc pt-2">
            <span className="fw-bold"> Address: </span>{" "}
            {locationDetail && locationDetail.locationAddress}
          </div>
          <div className="home-distance">
            <span className="location">
              {locationDetail && locationDetail.kmRange}KM
            </span>
          </div>
        </div>
        <div className="house-detail-banner">
          <img
            src={locationDetail && locationDetail.image}
            alt="house"
            width="300"
            className="img-fluid"
          />
        </div>
        <div className="house-detail-description my-3">
          <div className="t-t-t ">Description:</div>
          <p className="desc-p my-2">
            {locationDetail && locationDetail.description}
          </p>
        </div>
        {locationDetail && (
          <div
            className="your-element"
            // style={{ position: "relative", height: "150px", width: "100%" }}
          >
            {lat && lng && (
              <GoogleMapReact
                style={{ border: 0 }}
                bootstrapURLKeys={{ key: googleMapKey }}
                center={{
                  lat: lat,
                  lng: lng,
                }}
                defaultZoom={defaultProps.zoom}
              >
                <Marker
                  lat={lat}
                  lng={lng}
                  text="http://maps.google.com/mapfiles/ms/icons/red.png"
                />
              </GoogleMapReact>
            )}
            {/* <div className="cart-btn-container">
              <button type="button" className="btn btn-success btn-block">
                ADD TO CART
              </button>
            </div> */}
          </div>
        )}
        <div className=" ">
          <button
            className="location-cart-btn  my-3 "
            type="button"
            onClick={onAddToCard}
          >
            ADD TO CART
          </button>
        </div>
      </div>
    </div>
  );
};
export default Index;
