import * as actionTypes from "./types";

const initialState = {
  varDetailList: [],
  userLocationList: [],
  locationList:[],
  cartItemsList: {},
  locationDetail: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_VAR_DETAIL_LOCATION_LIST:
      return {
        ...state,
        varDetailList: action.payload,
      };
    case actionTypes.SET_LOCATION_LIST_BY_USER_ID:
      return {
        ...state,
        locationList: action.payload,
      };
      case actionTypes.SET_LOCATION_LIST_BY_CITY:
        console.log(state.locationList);  
        return {
          ...state,
          locationList: action.payload,
        };
      
    case actionTypes.SET_CART_ITEM_LIST:
      return {
        ...state,
        cartItemsList: action.payload,
      };
    case actionTypes.SET_LOCATION_DETAIL:
      return {
        ...state,
        locationDetail: action.payload,
      };
    case actionTypes.SET_USER_LOCATIONS:
      return {
        ...state,
        userLocationList: action.payload,
      };
    default:
      return state;
  }
}
