// import React from "react";
// import { limitWordHandler } from "../../../utils/common";
// import { useHistory } from "react-router-dom";

// export default ({ cartItemsList, onRemoveItemFromCart }) => {
//   const history = useHistory();

//   return (
//     <div>
//       <div className="page-11 form-container">
//         <table className="user-table">
//           <thead>
//             <tr>
//               <th className="fw-bold">Description</th>
//               <th className="fw-bold">Price</th>
//             </tr>
//           </thead>
//           <tbody>
//             {cartItemsList && cartItemsList.length ? (
//               cartItemsList.map((data, idx) => (
//                 <tr
//                   className="checkOutTwoAddCart"
//                   onClick={() =>
//                     history.push(`/admin-location-details-${data.id}`)
//                   }
//                   key={idx}
//                 >
//                   <td className="img-text-td">
//                     <div className="d-flex">
//                       {/* <img
//                         src="images/ic_cancel.svg"
//                         alt="cross"
//                         className="cross-img"
//                         onClick={(e) => {
//                           e.stopPropagation();
//                           onRemoveItemFromCart(data.cartId);
//                         }}
//                       /> */}
//                       <img
//                         src={
//                           data.itemImage
//                             ? data.itemImage
//                             : "images/single-location.jpg"
//                         }
//                       />
//                       <div className="img-text-content-part">
//                         <div>{data.itemName ? data.itemName : ""}</div>
//                         <div>
//                           {data.itemAddress
//                             ? limitWordHandler(data.itemAddress, 5)
//                             : ""}{" "}
//                         </div>
//                         {/* <div>Date: 25 March, 2021</div> */}
//                       </div>
//                     </div>
//                   </td>
//                   <td className="price-td">
//                     ${data.itemPrice ? data.itemPrice : "0"}
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="2">No Data Found!</td>
//               </tr>
//             )}

//             {/* <tr>
//             <td className="img-text-td">
//               <div className="d-flex">		<img src="images/ic_cancel.svg" alt="cross" className="cross-img" />
//                 <img src="images/single-location.jpg" />
//                 <div className="img-text-content-part">
//                   <div>Rich Calories</div>
//                   <div>Janobi Bazar, 1 New Bus Stand </div>
//                   <div>Date: 25 March, 2021</div>
//                 </div>
//               </div>
//             </td>
//             <td className="price-td">$200</td>
//           </tr> */}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

import React from "react";
import { limitWordHandler } from "../../../utils/common";
import { useHistory } from "react-router-dom";

export default ({ cartItemsList, onRemoveItemFromCart }) => {
  const history = useHistory();

  const renderCartCards = () => {
    return cartItemsList.map((data, idx) => (
      <div className="card mb-3 checkOutTwoAddCart" key={idx}>
        <div className="row g-0">
          <div className="col-md-4">
            <img
              src={
                data.itemImage ? data.itemImage : "images/single-location.jpg"
              }
              alt={data.itemName}
              className=" custom-image"
              style={{ height: "180px ", width:"100%",  objectFit:"cover"}}
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">{data.itemName || ""}</h5>
              <p className="card-text">
                {limitWordHandler(data.itemAddress, 8) || ""}
              </p>
              <p className="card-text fw-bold">
                Price: ${data.itemPrice || "0"}
              </p>
              <div className="">
                <i
                  className="fa-regular fa-trash-can p-2 rounded bg-danger text-white  float-right"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemoveItemFromCart(data.cartId);
                  }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <div className="page-11 form-container">
        {cartItemsList && cartItemsList.length ? (
          renderCartCards()
        ) : (
          <p>No Data Found!</p>
        )}
      </div>
    </div>
  );
};
