import React from "react";
import Routes from "./routes";
import Banner from "../common/LoginSideBanner/banner";

export default function Index() {
  return (
    <div
    //    style={{ height: "100%" }}
    >
      <div
        // style={{ height: "100%" }}
        className="login login-1 login-signin-on d-flex flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
      >
        {/* start Banner */}
        <Banner />
        {/* End Banner */}
        <Routes />
      </div>
    </div>
  );
}
