import * as actionTypes from "./types";

const initialState = {
  isAuthenticated: false,
  user: {},
  userProfileInfo: {},
  userTransaction: {},
  showModal:false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case actionTypes.CLEAR_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
    case actionTypes.SET_PROFILE_DATA:
      console.log(action.payload);
      return {
        ...state,
        user: action.payload,
      };
      case actionTypes.SHOW_MODAL:
        
        return {
          ...state,
          showModal: action.payload,
        };
        case actionTypes.CLOSE_SHOW_MODAL:
        
        return {
          ...state,
          showModal: action.payload,
        };
    case actionTypes.SET_TRANSACTION_DATA:
      return {
        ...state,
        userTransaction: action.payload,
      };

    default:
      return state;
  }
}
