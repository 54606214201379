import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import { validateEmail } from "../../../../utils/common";
import { Error } from "../../../../components/common/toastify";
import Loader from "../../../../components/common/Loader";
import { login } from "../../../../store/auth/actions";
import AuthModal from "./AuthModal";

const LoginForm = () => {
  const { showModal } = useSelector((state) => state.auth);
  console.log(showModal);
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.page);
  const [showPassword, setShowPassword] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [isShow, setisShow] = useState(false);
  const handleSubmit = () => {
    if (password === "") {
      Error("Password field is required!");
    } else {
      const reqPacket = {
        emailAddress,
        userName: emailAddress,
        password,
        userType: "7",
      };
      dispatch(login(reqPacket, history));
    }
  };

  return (
    <div>
      <div className="d-flex flex-column-fluid flex-center">
        {/* begin::Signin */}
        <div className="login-form login-signin">
          {/* begin::Form */}
          <img
            src="images/xp_eats.svg"
            alt="logo"
            className="envision-red-logo"
            width="300px"
          />
          <form
            className="login-form form fv-plugins-bootstrap fv-plugins-framework"
            id="kt_login_signin_form"
          >
            <div className="pb-7 pt-lg-0">
              <h3 className="welcome-title" style={{ textAlign: "center" }}>
                MOBILE DELIVERY FRANCHISE
              </h3>
            </div>
            {showModal && <AuthModal />}
            <div className="form-group fv-plugins-icon-container">
              <input
                className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                type="email"
                placeholder="Enter Email Address"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                style={{ cursor: "pointer", fontSize: "13px" }}
                className="show"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "HIDE" : "SHOW"}
              </span>
            </div>
            <Link to="/forgot-password-email" className="fortgot-password">
              FORGOT PASSWORD?
            </Link>
            <div className="d-flex pb-lg-0 pb-5">
              <Link to="/register">
                <button
                  type="button"
                  id="registerBtn"
                  style={{ fontSize: "15px" }}
                  className="gilroy-medium btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                >
                  REGISTER
                </button>
              </Link>
              <button
                type="button"
                id="kt_login_signin_submit"
                className="gilroy-medium btn btn-primary font-weight-bolder  px-8 py-4 my-3 mr-3"
                style={{ color: "629f05", fontSize: "15px" }}
                onClick={handleSubmit}
              >
                LOGIN
              </button>
            </div>
          </form>
          {/* end::Form */}
        </div>
        {/* end::Signin */}
      </div>
      <div className="footer-links">
        {/* <Link to="#">Terms Of Service</Link>
        <Link to="#">Privace &amp; policy</Link> */}
        <a
          href="https://xpeats.com/terms.html"
          target="_blank"
          rel="noreferrer"
        >
          TERMS OF SERVICE
        </a>
        &nbsp; &nbsp;
        <a
          href="https://xpeats.com/privacy.html"
          target="_blank"
          rel="noreferrer"
        >
          PRIVACY & POLICY
        </a>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default LoginForm;
