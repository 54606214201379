import React, { useEffect } from "react";
import Routes from "./routes";
import Nav from "./common/Nav/nav";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData, getUserTransaction } from "../store/auth/actions";
import { cartView } from "../store/location/actions";

export default function Index({location}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    const reqPacket = {
      userId: `${user.userId}`,
      userType: "user",
    };
    dispatch(getProfileData(reqPacket));
    dispatch(getUserTransaction(user.userId));
    dispatch(cartView(user.userId));
  }, []);
  const shouldDisplayNav = !['/success', '/failed'].includes(location.pathname);

  return (
    <div className="d-flex flex-column" style={{ height: "100%" }}>
      {/* start Nav */}
      {shouldDisplayNav && <Nav />}
      {/* End Nav */}
      <div
      // style={{ height: "100%" }}
      >
        <Routes />
      </div>
      {/* start Footer */}
      {/* End Footer */}
    </div>
  );
}

