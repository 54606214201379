import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { getCountries } from "../../../../store/common/actions";
import { registerUser } from "../../../../store/auth/actions";
import { registerValidationSchema } from "../../../../utils/YupSchema";

const initialValues = {
  firstName: "",
  lastName: "",
  userName: "",
  emailId: "",
  referralCode: "",
  contactNumber: "",
  currency: "",
  countryId: 1,
  password: "",
  confirmPassword: "",
  referralCode: "",
  imageFile: "",
};
export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.common);
  const [imageSrc, setImageSrc] = useState(null);
  const [countryCodesList, setCountryCodesList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const _file = e.target.files[0];
    reader.onload = (loadEvent) => {
      setImageSrc(loadEvent.target.result);
    };
    reader.readAsDataURL(_file);
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: registerValidationSchema,
    onSubmit: async (values, action) => {
      const requestPacket = {
        firstName: values.firstName,
        lastName: values.lastName,
        userName: values.userName,
        emailId: values.emailId,
        contactNumber: values.contactNumber,
        countryId: values.countryId,
        image: "image",
        password: values.password,
        confirmPassword: values.confirmPassword,
        referralCode: values.referralCode,
        roleId: "7",
        currency: values.currency,
      };
      const formData = new FormData();
      formData.append("request", JSON.stringify(requestPacket));
      formData.append("file", values.imageFile);
      dispatch(registerUser(formData, history));
    },
  });
  useEffect(() => {
    const countriesReqPacket = {
      channel: "",
      msisdn: "",
      email: "",
    };
    dispatch(getCountries(countriesReqPacket));
  }, []);

  useEffect(() => {
    if (countries && countries.length) {
      const countriesPhoneCodes = countries.filter(
        (country) => country.phonecode !== null
      );
      setCountryCodesList(countriesPhoneCodes);
    }
  }, [countries]);

  useEffect(() => {
    if (imageSrc) {
      processImage();
    }
  }, [imageSrc]);

  const processImage = () => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const cropWidth = Math.min(image.width, image.height);
      const cropHeight = Math.min(image.width, image.height);

      canvas.width = cropWidth;
      canvas.height = cropHeight;

      // Calculate cropping area
      const x = (image.width - cropWidth) / 2;
      const y = (image.height - cropHeight) / 2;

      // Crop the image and set cropped image URL
      ctx.drawImage(
        image,
        x,
        y,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );
      const croppedImageUrl = canvas.toDataURL("image/jpeg");
      setImagePreviewUrl(croppedImageUrl);
      console.log(canvas);
      canvas.toBlob((blob) => {
        const file = new File([blob], "cropped.jpg", { type: "image/jpeg" });
        setFieldValue("imageFile", file);
      }, "image/jpeg");
    };
  };
  return (
    <div>
      <div className="page-7 form-container">
        <div className="logo">
          <img src="images/xp_eats.svg" alt="XP Eats" width="300px" />
        </div>
        <div className="form-title" style={{ textAlign: "center" }}>
          MOBILE DELIVERY FRANCHISE
        </div>
        <form action="#" method="POST" onSubmit={handleSubmit}>
          <div className="name-img-section">
            <div className="user-img">
              <label className="custom-file-upload" style={{ height: "100%" }}>
                <div style={{ height: "100%" }}>
                  <img
                    style={{ height: "100%" }}
                    alt="No Image"
                    src={imagePreviewUrl || "images/ic_placeholder.svg"}
                  />
                </div>
                <input
                  id="photo-upload"
                  type="file"
                  onChange={photoUpload}
                  name="imageFile"
                  onBlur={handleBlur}
                />
              </label>
              {errors.imageFile && touched.imageFile ? (
                <p className="validationError">
                  <span class="font-medium">*{errors.imageFile}</span>
                </p>
              ) : null}
            </div>

            <div className="first-last-name-section">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="firstName"
                />
                {errors.firstName && touched.firstName ? (
                  <p className="validationError">
                    <span class="font-medium">*{errors.firstName}</span>
                  </p>
                ) : null}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="lastName"
                />
                {errors.lastName && touched.lastName ? (
                  <p className="validationError">
                    <span class="font-medium">*{errors.lastName}</span>
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Create Username"
              value={values.userName}
              onChange={handleChange}
              onBlur={handleBlur}
              name="userName"
            />
            {errors.userName && touched.userName ? (
              <p className="validationError">
                <span class="font-medium">*{errors.userName}</span>
              </p>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Referral Code (Optional)"
              value={values.referralCode}
              onChange={handleChange}
              onBlur={handleBlur}
              name="referralCode"
            />
            {errors.referralCode && touched.referralCode ? (
              <p className="validationError">
                <span class="font-medium">*{errors.referralCode}</span>
              </p>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Email Address"
              value={values.emailId}
              onChange={handleChange}
              onBlur={handleBlur}
              name="emailId"
            />
            {errors.emailId && touched.emailId ? (
              <p className="validationError">
                <span class="font-medium">*{errors.emailId}</span>
              </p>
            ) : null}
          </div>
          <div className="form-group country-numbers">
            <div
              className="country-code"
              style={{ display: "flex", gap: "10px" }}
            >
              <img src="images/053-canada.svg" />
              <select
                style={{ height: "100%", border: "none" }}
                className="form-select"
                name="countryId"
                onChange={(e) => {
                  setFieldValue("countryId", e.target.value);
                }}
                onBlur={handleBlur}
                defaultValue={values.countryId}
              >
                {countryCodesList.length &&
                  countryCodesList.map((obj, idx) => (
                    <option
                      key={idx}
                      value={obj.phonecode}
                    >{`+ ${obj.phonecode}`}</option>
                  ))}
              </select>
            </div>
            <input
              style={{ paddingLeft: "120px" }}
              className="form-control form-control-solid "
              type="number"
              placeholder=" (617) 397 - 8483"
              value={values.contactNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              name="contactNumber"
            />

            {errors.contactNumber && touched.contactNumber ? (
              <p className="validationError">
                <span class="font-medium">*{errors.contactNumber}</span>
              </p>
            ) : null}
          </div>
          <div className="form-group">
            <select
              id="currency"
              className="form-control"
              name="currency"
              onChange={(e) => {
                setFieldValue("currency", e.target.value);
              }}
              onBlur={handleBlur}
              defaultValue={values.currency}
            >
              <option value="" disabled selected hidden>
                Select Currency
              </option>
              <option value="CAD">CAD</option>
              <option value="USD">USD</option>
            </select>
            {errors.currency && touched.currency ? (
              <p className="validationError">
                <span class="font-medium">*{errors.currency}</span>
              </p>
            ) : null}
          </div>

          <div className="form-group">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              placeholder="Create Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              name="password"
            />

            <span
              style={{ cursor: "pointer", fontSize: "13px" }}
              className="show-pass"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "HIDE" : "SHOW"}
            </span>
            {errors.password && touched.password ? (
              <p className="validationError">
                <span class="font-medium">*{errors.password}</span>
              </p>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type={showConfirmPassword ? "text" : "password"}
              className="form-control"
              placeholder="Confirm Password"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              name="confirmPassword"
            />
            <span
              style={{ cursor: "pointer", fontSize: "13px" }}
              className="show-pass"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? "HIDE" : "SHOW"}
            </span>
            {errors.confirmPassword && touched.confirmPassword ? (
              <p className="validationError">
                <span class="font-medium">*{errors.confirmPassword}</span>
              </p>
            ) : null}
          </div>
          <Link to="/login" className="kindly-login">
            Kindly log in if you've already registered?
          </Link>
          <div className="form-group">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-register"
            >
              REGISTER
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
