import Autocomplete from "react-google-autocomplete";
import styled from "styled-components";

const PlacesAutoCompleteInput = ({ label, handleChange, showLabel = true }) => {
  return (
    <Wrapper>
      {showLabel && <label htmlFor="places-suggestion">{label}</label>}
      <div style={{ width: "300px" }}>
        <Autocomplete
          id="places suggestions"
          placeholder="Search (city, username, name)"
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          onPlaceSelected={(place) => {
            console.log(place);
            handleChange(place.formatted_address || "");
          }}
        />
      </div>
    </Wrapper>
  );
};

export default PlacesAutoCompleteInput;

export const Wrapper = styled.div`
  label {
    font-weight: 500;
  }
  input {
    border: 1px solid grey;
    padding: 8px;
    width: 100%;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 500;
  }
`;
