import React, { useState } from "react";
import '../LoginModal.css'
import { useDispatch, useSelector } from "react-redux";
import { closeFailedModal } from "../../../../store/auth/actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
function AuthModal() {
  const { showModal } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeFailedModal(false));
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        animation={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      className="modal-custom"
      >
        <Modal.Header className="d-flex justify-content-center align-items-center">
          <div className="text-center">
            <h4>Login Failed</h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{marginTop:"3px"}} className="text-center">
            <p>Your email or password is incorrect</p>
            <p>Please try again.</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center" onClick={handleClose}>
          {/* <Button variant="secondary" > */}
            OK
          {/* </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AuthModal;
