import React from "react";
import ForgotPasswordEmailForm from "./components/forgotPasswordEmailForm";

export default function ForgetPasswordScreen() {
  return (
    <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
      {/* begin::forgotEmailForm */}
      <ForgotPasswordEmailForm />
      {/* End::forgotEmailForm */}

      <div className="footer-links">
        <a href="#">Terms of Service</a>
        <a href="#">Privacy Policy</a>
        <a href="#">Contact Us</a>
      </div>
    </div>
  );
};
