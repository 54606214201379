import React from "react";
import ChangePasswordForm from "./components/changePasswordForm";

export default function Index() {
  return (
    <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
      {/* begin::Content body */}
      {/* begin::ChangePasswordForm */}
      <ChangePasswordForm />
      {/* end::ChangePasswordForm */}
    </div>
  );
};
