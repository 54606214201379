import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
// eslint-disable-next-line no-unused-vars
import { Success, Error } from "../../components/common/toastify";
const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    // eslint-disable-next-line no-unused-vars
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      //   console.log('[error]', error);
      Error(error.message);
    } else {
      //   console.log('[PaymentMethod]', paymentMethod);
      // const user = getCuurentUser();
      // const { data } = await axios.post(
      //   'https://www.ennvisionapistore.com:8445/eats/apistore/addCreditCard',
      //   {
      //     creditCardToken: paymentMethod.id,
      //     brand: paymentMethod.card.brand,
      //     last4: paymentMethod.card.last4,
      //     userId: user.userId,
      //   }
      // );
      // console.log('payment api response', data);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 mt-t"></div>
        <div className="col-md-4 mt-5">
          <form onSubmit={handleSubmit}>
            <div
              style={{
                border: "1px solid black",
                height: "40px",
                padding: "10px",
              }}
            >
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
            </div>

            <button
              type="submit"
              className="btn btn-success btn-block mt-5"
              disabled={!stripe}
            >
              Pay
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
