// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { useParams, useHistory } from "react-router-dom";
// import {
//   cartView,
//   deleteCartItem,
//   chargeCustomerByLocation,
// } from "../../store/location/actions";
// import {
//   loadAllCreditCards,
//   createStripeToken,
//   markCreditCardDefault,
// } from "../../store/common/actions";
// import LocationDescription from "./components/LocationDescription";
// import CheckOut from "./components/CheckOut";
// import PaymentMethod from "./components/PaymentMethod";
// import AddCard from "../addCard";
// import * as toastActions from "../../components/common/toastify";
// import Loader from "../../components/common/Loader";
// import { Elements } from "@stripe/react-stripe-js";
// import StripePaymentForm from "../../components/StripePaymentForm";
// import { loadStripe } from "@stripe/stripe-js";

// import CheckoutForm from "../../components/CheckoutForm";
// export default function Index() {
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const { userId } = useParams();
//   const { loading } = useSelector((state) => state.page);
//   const { cartItemsList } = useSelector((state) => state.location);
//   const { creditCardList } = useSelector((state) => state.common);
//   const [showAddCard, setShowAddCard] = useState(false);

//   const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

//   // const options = {
//   //   // passing the client secret obtained from the server
//   //   clientSecret: process.env.REACT_APP_STRIPE_KEY,
//   // };
//   console.log(cartItemsList, "cart items in index.js")

//   useEffect(() => {
//     dispatch(cartView(userId));
//     const reqPacket = {
//       userId,
//     };
//     dispatch(loadAllCreditCards(reqPacket));
//   }, []);

//   useEffect(() => {
//     setShowAddCard(false);
//   }, [creditCardList]);

//   const onRemoveItemFromCart = (cartId) => {
//     const reqPacket = {
//       cartId,
//       userId,
//     };
//     dispatch(deleteCartItem(reqPacket));
//   };

//   const createStripeTokenHandler = (reqPacket) => {
//     dispatch(createStripeToken(reqPacket, userId ? Number(userId) : 0));
//   };

//   const markCreditCardDefaultHandler = (card) => {
//     const reqPacket = {
//       cardId: card ? card.cardId : "",
//       stripeCardId: card.stripeCardId ? card.stripeCardId : "",
//       userId,
//     };
//     dispatch(markCreditCardDefault(reqPacket));
//   };

//   // const payNowButtonHandler = () => {
//   //   const activeCard = creditCardList.filter(
//   //     (data) => data.isDefault === true
//   //   )[0];

//   //   let locationIDs = "";
//   //   cartItemsList.cartItemsList.map((data, idx) => {
//   //     if (idx === 0) {
//   //       locationIDs += `${data.itemId}`;
//   //     } else {
//   //       locationIDs += `,${data.itemId}`;
//   //     }
//   //     return data;
//   //   });
//   //   if (activeCard && activeCard.cardId) {
//   //     const reqPacket = {
//   //       userId,
//   //       cardId: `${activeCard.cardId}`,
//   //       locationIDs,
//   //     };
//   //     console.log(reqPacket);
//   //     dispatch(chargeCustomerByLocation(reqPacket, history));
//   //   } else {
//   //     toastActions.Warn("Please Select any Card!");
//   //   }
//   // };

//   const payNowButtonHandler = () => {
//     const activeCard = creditCardList.filter(
//       (data) => data.isDefault === true
//     )[0];

//     let locationIDs = "";
//     cartItemsList.cartItemsList.map((data, idx) => {
//       if (idx === 0) {
//         locationIDs += `${data.itemId}`;
//       } else {
//         locationIDs += `,${data.itemId}`;
//       }
//       return data;
//     });

//     if (activeCard && activeCard.cardId) {
//       const reqPacket = {
//         userId,
//         cardId: `${activeCard.cardId}`,
//         locationIDs,
//       };
//       console.log(reqPacket);

//       dispatch(chargeCustomerByLocation(reqPacket, history));
//     } else {
//       toastActions.Warn("Please Select any Card!");
//     }
//   };

// const handlePayment = async () => {
//   console.log(cartItemsList.cartItemsList);
//   const stripe = await loadStripe('pk_test_YsTMWo7ZLJX3K6QZYujQ0BA3');

//   const body={
//      cartItemsList: cartItemsList.cartItemsList,

//   }
// console.log(body)
//   const header = {
//     "Content-Type": "application/json"
//   };

//   try {
//     const response = await fetch("https://xp.life:8443/eats/apistore/create-checkout-session", {
//       method: "POST",
//       headers: header,
//       body: JSON.stringify(body)
//     });

//     if (response.status === 200) {
//       console.log("Checkout API hits");

//       const session = await response.json();
//       console.log(session);

//       const data = session.sessionId;
//       console.log(data);

//       const result = stripe.redirectToCheckout({
//         sessionId: session.sessionId
//       });

//       if (result.error) {
//         console.log(result.error);
//       }
//     } else {
//       console.error("Checkout API returned an error:", response.status);
//     }
//   } catch (error) {
//     console.error("An error occurred while processing the payment:", error);
//   }
// };

//   return (
//     <div
//       className="page11-custom checkout-page checkout-variant-2"
//       style={{ height: "100%" }}
//     >
//       {loading ? (
//         <Loader />
//       ) : cartItemsList.cartItemsList && cartItemsList.cartItemsList.length ? (
//         <div className="profile-page11">
//           {/* start LocationDescription */}
//           <LocationDescription
//             cartItemsList={
//               cartItemsList.cartItemsList ? cartItemsList.cartItemsList : []
//             }
//             onRemoveItemFromCart={onRemoveItemFromCart}
//           />
//           {/* End LocationDescription */}

//           <div className="profile-section">
//             {/* start CheckOut */}
//             <CheckOut
//               subTotal={cartItemsList.subTotal ? cartItemsList.subTotal : 0}
//               tax={cartItemsList.tax ? cartItemsList.tax : 0}
//               total={cartItemsList.total ? cartItemsList.total : 0}
//             />
//             {/* End checkOut */}
// {/*
//             <div>
//               <Elements stripe={stripePromise}>
//                 <StripePaymentForm />
//               </Elements>
//             </div> */}
//             <div className=" my-5">
//                      <button className="btn btn-danger" onClick={handlePayment}>
//                       Pay now

//                      </button>
//             </div>

//             {/* Start CheckOut */}
//             {!showAddCard && (
//               <PaymentMethod
//                 setShowAddCard={setShowAddCard}
//                 creditCardList={creditCardList}
//                 markCreditCardDefaultHandler={markCreditCardDefaultHandler}
//                 payNowButtonHandler={payNowButtonHandler}
//               />
//             )}

//             {/* End CheckOut */}
//             {showAddCard && (
//               <AddCard
//                 setShowAddCard={setShowAddCard}
//                 createStripeTokenHandler={createStripeTokenHandler}
//               />
//             )}
//           </div>
//         </div>
//       ) : (
//         <div
//           style={{
//             textAlign: "center",
//             padding: "20px",
//             height: "100%",
//             display: "flex",
//             alignItems: "center",
//             fontFamily: "Roboto Condensed, sans-serif",
//           }}
//         >
//           <div style={{ flex: 1 }}></div>
//           <div style={{ flex: 1 }}>
//             <h3>
//               Your cart is empty! <br /> Click below to reserve a location.
//             </h3>
//             <button
//               className="location-common-btn"
//               type="button"
//               onClick={() => history.push("/")}
//             >
//               CONTINUE SHOPPING
//             </button>
//           </div>
//           <div style={{ flex: 1 }}></div>
//         </div>
//       )}
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  addBuyerLocations,
  cartView,
  deleteCartItem,
} from "../../store/location/actions";
import { loadAllCreditCards } from "../../store/common/actions";
import LocationDescription from "./components/LocationDescription";
import CheckOut from "./components/CheckOut";

import Loader from "../../components/common/Loader";

import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "../../components/CheckoutForm";
export default function Index() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams();
  const { loading } = useSelector((state) => state.page);
  const { cartItemsList } = useSelector((state) => state.location);
  const { creditCardList } = useSelector((state) => state.common);
  const [showAddCard, setShowAddCard] = useState(false);

  // console.log(cartItemsList, "cart items in index.js");
  
  useEffect(() => {
    dispatch(cartView(userId));
    const reqPacket = {
      userId,
    };
    dispatch(loadAllCreditCards(reqPacket));
    localStorage.removeItem("cartData")
  }, []);

  useEffect(() => {
    setShowAddCard(false);
  }, [creditCardList]);

  //////////////////// Remove Item From cart /////////////////////////////////
  const onRemoveItemFromCart = (cartId) => {
    const reqPacket = {
      cartId,
      userId,
    };
    console.log(reqPacket)
    dispatch(deleteCartItem(reqPacket));
  };

  //////////////////////////////// Payment Here ////////////////////////////////////////////
  // const StatusPacket = cartItemsList?.cartItemsList;

  // const reqPacket = {
  //   locationId: StatusPacket?.[0]?.itemId,
  //   buyerId: StatusPacket?.[0]?.userId,
  //   paymentMethod: "Credit Card",
  // };
  

  console.log(cartItemsList);
  const handlePayment = async () => {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

    const body = {
      cartItemsList: cartItemsList.cartItemsList,
    };
    console.log(body);
    const header = {
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(
        "https://xp.life:8443/eats/apistore/create-checkout-session",
        {
          method: "POST",
          headers: header,
          body: JSON.stringify(body),
        }
      );

      if (response.status === 200) {
        console.log("Checkout API hits");

        const session = await response.json();
        console.log(session);

        const data = session.sessionId;
        console.log(data);
        ////////////// cart in localstorage //////////////////////
        const cartData = localStorage.setItem(
          "cartData",
          JSON.stringify(cartItemsList.cartItemsList)
        );
        /////////////////////////////////////////////////////////
        const result = stripe.redirectToCheckout({
          sessionId: session.sessionId,
        });
console.log(result.data)
        if (result.error) {
          console.log(result.error);
        }
      } else {
        console.error("Checkout API returned an error:", response.status);
      }
    } catch (error) {
      console.error("An error occurred while processing the payment:", error);
    }
  };

  return (
    <div
      className="page11-custom checkout-page checkout-variant-2"
      style={{ height: "100%" }}
    >
      {loading ? (
        <Loader />
      ) : cartItemsList.cartItemsList && cartItemsList.cartItemsList.length ? (
        <div className="profile-page11">
          {/* start LocationDescription */}
          <LocationDescription
            cartItemsList={
              cartItemsList.cartItemsList ? cartItemsList.cartItemsList : []
            }
            onRemoveItemFromCart={onRemoveItemFromCart}
          />
          {/* End LocationDescription */}

          <div className="profile-section">
            {/* start CheckOut */}
            <CheckOut
              subTotal={cartItemsList.subTotal ? cartItemsList.subTotal : 0}
              tax={cartItemsList?.tax ? cartItemsList.tax : 0}
              total={cartItemsList.total ? cartItemsList.total : 0}
            />
            {/* End checkOut */}

            <div className=" my-5">
              <button className="btn btn-danger w-100" onClick={handlePayment}>
                Pay now
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            fontFamily: "Roboto Condensed, sans-serif",
          }}
        >
          <div style={{ flex: 1 }}></div>
          <div style={{ flex: 1 }}>
            <h3>
              Your cart is empty! <br /> Click below to reserve a location.
            </h3>
            <button
              className="location-common-btn"
              type="button"
              onClick={() => history.push("/")}
            >
              CONTINUE SHOPPING
            </button>
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
      )}
    </div>
  );
}
