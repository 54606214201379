import React from "react";

export default ({ subTotal, tax, total }) => {
  return (
    <div>
      <div className="profile-info">
        <div className="top-part">Check Out</div>
        <div className="info-details">
          <div className="name fw-bold">Subtotal</div>
          <div className="e-name">${subTotal}</div>
        </div>
        <div className="info-details">
          <div className="name fw-bold">Tax</div>
          <div className="e-name">${tax}</div>
        </div>
        <div className="info-details">
          <div className="name fw-bold">Total</div>
          <div className="e-name">${total}</div>
        </div>
      </div>
    </div>
  );
};
