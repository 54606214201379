// import React from "react";
// import { useHistory } from "react-router-dom";
// import { limitWordHandler } from "../../utils";
// // import { limitWordHandler } from "../../../utils";

// export default ({ locationList }) => {
//   const history = useHistory();
//   return (
//     <div>
//       <div className="page-11 form-container">
//         {locationList && locationList.length ? (
//           <table className="user-table">
//             <thead>
//               <tr>
//                 <th className="fw-bold">Product</th>
//                 <th className="fw-bold">Earnings</th>
//                 <th className="fw-bold">Device Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {locationList && locationList.length ? (
//                 locationList.map((data, index) => (
//                   <tr
//                     className="manageLocationProduct"
//                     onClick={() =>
//                       history.push(`/admin-location-details-${data.id}`)
//                     }
//                     key={index}
//                   >
//                     <td className="img-text-td">
//                       <div className="d-flex">
//                         <img
//                           src={
//                             data.locationImage
//                               ? data.locationImage
//                               : "images/single-location.jpg"
//                           }
//                         />
//                         <div className="img-text-content-part">
//                           <div className="fw-bold">
//                             {data.locationTitle && data.locationTitle}
//                           </div>
//                           <div>
//                             {data.locationDescription &&
//                               limitWordHandler(
//                                 data.locationDescription,
//                                 5
//                               )}{" "}
//                           </div>
//                           <div>Date: {data.buyDate && data.buyDate}</div>
//                         </div>
//                       </div>
//                     </td>
//                     <td className="price-td">{data.userEarning ?? 0}</td>
//                     <td className="booked-td">{data.status && data.status}</td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td className="img-text-td"></td>
//                   <td className="price-td"></td>
//                   <td className="booked-td"></td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         ) : (
//           <div
//             style={{
//               textAlign: "center",
//               padding: "20px",
//               fontFamily: "Roboto Condensed, sans-serif",
//             }}
//           >
//             <h3>
//               You have not reserved a location. <br /> Click Continue to make a
//               purchase
//             </h3>
//             <button
//               className="location-common-btn"
//               type="button"
//               onClick={() => history.push("/")}
//             >
//               CONTINUE
//             </button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

import React from "react";
import { useHistory } from "react-router-dom";
import { limitWordHandler } from "../../utils";

export default ({ locationList }) => {
  const history = useHistory();
console.log(locationList)
  const renderLocationCards = () => {
    return locationList.map((data, index) => (
      <div
        className="card mb-3 manageLocationProduct"
        key={index}
        onClick={() => history.push(`/manage-location-details-${data.id}`)}
      >
        <div className="row g-0">
          <div className="col-md-4">
            <div>
              <img
                src={
                  data.locationImage
                    ? data.locationImage
                    : "images/single-location.jpg"
                }
                alt={data.locationTitle}
                className="img-fluid"
                // style={{ height: "200px" }}
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5
                className="card-title fw-bold m-0"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {/* {data.locationTitle.substring(0, 35)} */}
                {limitWordHandler(data.locationTitle, 5)}
              </h5>
              <p className="card-text m-0">
                {limitWordHandler(data.locationDescription, 5)}
              </p>
              <p className="card-text m-0">
                <small className="text-muted">Date: {data.buyDate}</small>
              </p>
              <p className="card-text fw-bold m-0">
                Earnings: {data.tabletFee ?? 0}
              </p>
              <p className="card-text fw-bold m-0">
                Device Status: {data.status}
              </p>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <div className="page-11 form-container">
        {locationList && locationList.length ? (
          renderLocationCards()
        ) : (
          <div
            style={{
              textAlign: "center",
              padding: "20px",
              fontFamily: "Roboto Condensed, sans-serif",
            }}
          >
            <h3>
              You have not reserved a location. <br /> Click Continue to make a
              purchase
            </h3>
            <button
              className="location-common-btn"
              type="button"
              onClick={() => history.push("/")}
            >
              CONTINUE
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
