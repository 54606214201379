import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser, getProfileData } from "../../../store/auth/actions";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import PlacesAutoCompleteInput from "../../../components/AutoCompleteAddress";
import { NavLink } from "react-router-dom";

export default function PlacesAutocompleteComponent() {
  const { user } = useSelector((state) => state.auth);
  console.log(user.image);
  const { cartItemsList } = useSelector((state) => state.location);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [address, setAddress] = useState("");

  const handleLogout = () => {
    dispatch(logoutUser());
    history.push("/login");
  };

  // useEffect(() => {
  //   const reqPacket = {
  //     userId: `${user.userId}`,
  //     userType: "user",
  //   };
  //   dispatch(getProfileData(reqPacket));
  // }, []);
  const handleNavigate = (url) => {
    const reqPacket = {
      userId: `${user.userId}`,
      userType: "user",
    };
    dispatch(getProfileData(reqPacket));
    history.push(url);
  };
  const handleSelect = (addressParam) => {
    const add1 = addressParam.split(", ");
    let queryParams = null;
    if (add1.length === 2) {
      queryParams = new URLSearchParams({
        cityName: add1[0],
        countryName: add1[1],
      });
    } else if (add1.length === 3) {
      queryParams = new URLSearchParams({
        cityName: add1[0],
        countryName: add1[2],
        stateName: add1[1],
      });
    }
    const query = new URLSearchParams(queryParams);
    history.push(`/admin-location-grid-view-1?${query}`);

    // geocodeByAddress(address)
    //   .then((results) =>{
    //     console.log(results);
    //     getLatLng(results[0])})
    //   .then((latLng) => {
    //     this.setState({
    //       latitude: latLng.lat,
    //       longitude: latLng.lng,
    //     });
    //     console.log("Success", latLng);
    //   })
    //   .catch((error) => console.error("Error", error));
  };

  return (
    <div style={{ height: "fit-content" }}>
      {/* <nav className="flow-navbar navbar navbar-expand-lg navbar-light bg-light">
        <div className="max-width">
          <Link className="navbar-brand" to="#">
            <img src="images/xp_eats.svg" alt="Envision" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse header-collapsible-link"
            id="navbarNav"
          > */}
      {/* <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link" to="/admin-location-list-view">
                  LOCATIONS LIST VIEW<span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin-location-grid-view">
                  LOCATIONS GRID VIEW
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin-location-status">
                  LOCATIONS STATUS
                </Link>
              </li>
            </ul> */}
      {/* <ul className="navbar-nav">
              <div className="select-box">
                <img src="images/pin.svg" alt="pin" />
                <select className="form-control">
                  <option>Select Country</option>
                  <option>Canada</option>
                  <option>Pakistan</option>
                </select>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <div className="search-box">
                <img src="images/ic_search.svg" alt="search" />
                <input type="text" name="search" placeholder="Search" />
              </div>
            </ul>
            <div className="header-right-part"> */}
      {/* <span>
        <Link to="#">
          <img src="images/ic_search.svg" />
        </Link>
      </span> */}
      {/* <span className="position-relative cart-container">
                <span className="counter">8</span>
                <Link to="#">
                  <i className="fa fa-shopping-cart" aria-hidden="true" />
                </Link>
              </span> */}
      {/* <span>
                <Link to="#">
                  <img src="images/053-canada.svg" />
                </Link>
              </span> 
      <span className="user-name">Hi, Adnan</span>
              <span className="right-user">
                <Link to="#">
                  <img src="images/032-boy-13.svg" />
                </Link>
              </span>
            {/*</div>
          </div>
        </div>
      </nav> */}
      <nav
        className="user-nav flow-navbar navbar navbar-expand-lg navbar-light bg-light"
        // style={{ position: "relative", zIndex: 9 }}
      >
        <div className="max-width">
          <Link className="navbar-brand" to="/list-view">
            <img src="images/xp_eats.svg" alt="Envision" />
          </Link>

          <div className="mobile_menu ">
            {/* <span>
            {location.pathname === "/location-status" ?  "Manage Locations" : "Dashboard"}
          </span> */}

            <span className="position-relative cart-container ">
              <span
                style={{
                  position: "absolute",
                  color: "#fff",
                  background: "red",
                  fontSize: "8px",
                  minWidth: "11px",
                  textAlign: "center",
                  minHeight: "12px",
                  margin: 0,
                  right: "-5px",
                  top: "-4px",
                  padding: "0px 2px",
                }}
              >
                {cartItemsList &&
                cartItemsList.cartItemsList &&
                cartItemsList.cartItemsList.length
                  ? cartItemsList.cartItemsList.length
                  : 0}
              </span>
              <Link
                to={`/checkout-two-${user && user.userId ? user.userId : 0}`}
              >
                {/* <i
                  style={{ color: "#1b283f", fontSize: "21px" }}
                  className="fa fa-shopping-cart"
                  aria-hidden="true"
                /> */}
                <img
                  src="/images/ic_cart.svg"
                  alt="Cart"
                  className=" mx-auto"
                  style={{
                    color: "#1b283f",
                    fontSize: "21px",
                    width: "25px",
                  }}
                />
              </Link>
            </span>

            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse header-collapsible-link"
              id="navbarNav"
              style={{ display: showMobileMenu ? "block" : "none" }}
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <NavLink
                    className="nav-link"
                    activeClassName="active-link" 
                    to="/list-view"
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active-link" 
                    style={{
                      whiteSpace: "nowrap",
                      // fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    to="/location-status"
                  >
                    Manage Locations
                  </NavLink>
                </li>

                <li className="nav-item">
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#1b283f",
                      // fontWeight: "bold",
                    }}
                  >
                    {user && user.availableBlance
                      ? user.availableBlance
                      : "$0.00"}
                    CAD
                  </span>
                </li>
                {/* <li>
                  <span className="position-relative cart-container">
                    <span
                      style={{
                        position: "absolute",
                        color: "#fff",
                        background: "red",
                        fontSize: "8px",
                        minWidth: "11px",
                        textAlign: "center",
                        minHeight: "12px",
                        margin: 0,
                        right: "-5px",
                        top: "-4px",
                        padding: "0px 2px",
                      }}
                    >
                      {cartItemsList &&
                      cartItemsList.cartItemsList &&
                      cartItemsList.cartItemsList.length
                        ? cartItemsList.cartItemsList.length
                        : 0}
                    </span>
                    <Link
                      to={`/checkout-two-${
                        user && user.userId ? user.userId : 0
                      }`}
                    >
                      <i
                        style={{ color: "#1b283f", fontSize: "21px" }}
                        className="fa fa-shopping-cart"
                        aria-hidden="true"
                      />
                    </Link>
                  </span>
                </li> */}
                <li className="nav-item">
                  <span className="right-user">
                    <button
                      className="btn btn-success logout logout-button my-2"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {(pathname === "/" ||
            pathname === "/list-view" ||
            pathname.indexOf("/admin-location-grid-view") > -1) && (
            <ul className="navbar-nav">
              <PlacesAutoCompleteInput
                label="places"
                showLabel={false}
                handleChange={(data) => handleSelect(data)}
                style={{ width: "400px !important" }}
              />

              {/* <PlacesAutocomplete
                  value={address}
                  onChange={(addr) => setAddress(addr)}
                  onSelect={handleSelect}
                  googleCallbackName="initAutocomplete"
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="search-box">
                      <img src="images/ic_.svg" alt="search" />
                      <input
                        {...getInputProps({
                          placeholder: "Search (city, username, name)",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                              position: "relative",
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              zIndex: 99999,
                              padding: "10px",
                              boxShadow: "0px 0px 10px #777",
                              overflowY: "scroll",
                            }
                            : {
                              position: "relative",
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                              zIndex: 99999,
                              overflowY: "scroll",
                            };
                          return (
                            <div
                              key={Math.random()}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete> */}
            </ul>
          )}
          <div className="header-right-part">
            <div
              className="collapse navbar-collapse header-collapsible-link"
              id="navbarNav"
            >
              <div className="navbar-nav  ">
                <div className="navbar-nav-inner-section ">
                  <span
                    style={{
                      fontSize: "14px",
                      marginRight: "30px",
                    }}
                  >
                    <NavLink
                       activeClassName="active-link" 
                      style={{
                        color: "#1b283f",
                        textDecoration: "none",
                        whiteSpace: "nowrap",
                        // fontWeight: "bold",
                      }}
                      to="/list-view"
                    >
                      Dashboard
                    </NavLink>
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    <a
                       activeClassName="active-link" 

                     
                      onClick={() => handleNavigate("/location-status")}
                      href="#"
                      style={{
                        color: "#1b283f",
                        textDecoration: "none",
                        whiteSpace: "nowrap",
                        // fontWeight: "bold",
                      }}
                    >
                      {/* <Link
                      style={{
                        color: "#1b283f",
                        textDecoration: "none",
                        whiteSpace: 'nowrap'
                      }}
                      to="/location-status"
                    > */}
                      Manage Locations
                      {/* </Link> */}
                    </a>
                  </span>
                </div>
                <span className="position-relative cart-container">
                  <span className="counter">
                    {cartItemsList &&
                    cartItemsList.cartItemsList &&
                    cartItemsList.cartItemsList.length
                      ? cartItemsList.cartItemsList.length
                      : 0}
                  </span>
                  <NavLink
                     activeClassName="active-link" 
                    //  target="blank"
                    to={`/checkout-two-${
                      user && user.userId ? user.userId : 0
                    }`}
                  >
                    <img src="/images/ic_cart.svg" alt="Cart" />
                  </NavLink>
                </span>

                <span
                  style={{
                    fontSize: "14px",
                    color: "#1b283f",
                    margin: "10px 30px 0px 7px",

                    // fontWeight: "bold",
                  }}
                >
                  {user && user.availableBlance
                    ? user.availableBlance
                    : "$0.00"}
                  CAD
                </span>
                {/* <span
                  style={{
                    fontSize: '14px',
                    color: '#1b283f',
                  }}
                >
                  {user && user.availableBlance}
                </span> */}
                {/* <span>
                <a href="#">
                  <img src="images/ic_search.svg" />
                </a>
              </span> */}

                <span>
                  {/* <Link to="/profile">
                    <img src={user?.image ?? "images/032-boy-13.svg"} />
                  </Link> */}
                  <a
                    className="nav-image"
                    onClick={() => handleNavigate("/profile")}
                    href="#"
                  >
                    <img src={user.image ?? "images/032-boy-13.svg"} />
                    <span
                    className="user-name"
                    style={{ fontWeight: "bold", marginLeft: "10px" }}
                  >
                    {user && user.firtsName}
                  </span>
                  </a>
                
                </span>
                <span className="right-user">
                  <button
                    className="btn btn-success logout logout-button ms-5"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
