import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getVARLocDetailList } from "../../store/location/actions";
import MarkerInfoWindow from "./components/googleMap/gMap";
import VarDetailModal from "./components/modal";
import Loader from "../../components/common/Loader";

export default function GetLocations() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.page);
  const { varDetailList, locationList } = useSelector(
    (state) => state.location
  );
  console.log("buyin locations".locationList);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [varDetail, setVarDetail] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    const updatedVarDetail = varDetailList.filter((data) => data.userId === id);
    setVarDetail(updatedVarDetail[0]);
    setShow(true);
  };

  const [locationListMap, setLocationListMap] = useState([]);
  console.log(locationListMap, "location map");
  useEffect(() => {
    const reqPacket = {
      userId: `${user.userId}`,
      userDetail: "user",
    };
    dispatch(getVARLocDetailList(reqPacket));
  }, []);

  useEffect(() => {
    if (varDetailList && varDetailList.length) {
      let allLocationList = [];
      for (const data of varDetailList) {
        allLocationList = [...allLocationList, ...data.locationsList];
      }
      setLocationListMap(allLocationList);
    }
  }, [varDetailList]);

  useEffect(() => {
    if (locationList && locationList.length) {
      setLocationListMap(locationList);
    }
  }, [locationList]);

  const onAddLocation = (userId) => {
    history.push(`/admin-location-grid-view-${userId}`);
  };

  return (
    <div className="location-container page-12-location">
      <VarDetailModal
        show={show}
        handleClose={handleClose}
        varDetail={varDetail}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="location-section">
          {/* {console.log(varDetailList)} */}
          {varDetailList && varDetailList.length ? (
            varDetailList.map((card, index) => (
              <div key={index} className="detail-info-location">
                {/* <List
                  {...card}
                  onAdd={onAddLocation}
                  getLocation={handleGetLocations}
                /> */}
                <div className="user-main-container">
                  <div className="user-main">
                    <div className="user-dp-text">
                      {card.userImage &&
                      card.userImage !== "image" &&
                      card.userImage !== "" ? (
                        <img
                          style={{ width: "85px", height: "85px" }}
                          src={card.userImage}
                          alt="user"
                          onClick={() => handleShow(card.userId)}
                        />
                      ) : (
                        <img
                          onClick={() => handleShow(card.userId)}
                          src="images/ic_var.png"
                          alt="user"
                        />
                      )}
                      <div className="details-t">
                        {/* <div className="user-details-t">
                            <span className="text-b">Full Name: </span>
                            <span>{card.fullName}</span>
                          </div> */}
                        <div className="user-details-t">
                          <span className="text-b fw-bold">Username: </span>
                          <span>{card.userName}</span>
                        </div>
                        <div className="user-details-t">
                          <span
                            // target="_black"
                            // href={`/admin-location-grid-view-${card.userId}`}
                            onClick={() => onAddLocation(card.userId)}
                            className="text-b"
                            style={{
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            Locations
                          </span>
                           {/* <span
                      style={{ cursor: "pointer" }}
                      className="plus-sign"
                      onClick={() => onAddLocation(card.userId)}
                    >
                      locations
                    </span> */}
                        </div>
                        <div>
                          <i
                            onClick={() => handleShow(card.userId)}
                            style={{ color: "blue" }}
                            className="fa fa-info-circle"
                          ></i>
                        </div>
                        {/* <div className="user-details-t">
                          <span className="text-b">Contact Number: </span>
                          <span>{card.contactNumber}</span>
                        </div> */}
                      </div>
                    </div>
                    <div style={{ textAlign: "end" }}>
                      <div style={{ minWidth: "90px", fontSize: "13px" }}>
                        {card.locationCount}
                        <span style={{}}> Available</span>
                      </div>
                      <div style={{ minWidth: "90px", fontSize: "13px" }}>
                        {card.locationBooked ?? 0} Booked
                      </div>
                    </div>
                  </div>
                  <div className="user-gallery" style={{ gap: "7px" }}>
                    {card.locationsList &&
                      card.locationsList
                        .slice(0, 5)
                        .map((locationItem, idx) => (
                          <Link
                            key={idx}
                            to={`/admin-location-details-${locationItem.locationId}`}
                          >
                            <img
                              style={{ marginRight: "none" }}
                              src={
                                locationItem.locationImage !== "image" &&
                                locationItem.locationImage !== ""
                                  ? locationItem.locationImage
                                  : "images/ic_var.png"
                              }
                            />
                          </Link>
                        ))}
                    <span
                      style={{ cursor: "pointer" }}
                      className="plus-sign"
                      onClick={() => onAddLocation(card.userId)}
                    >
                      <i className="fa fa-plus" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h2 style={{ margin: "auto" }}></h2>
          )}
        </div>
      )}
      <div className="location-map">
        <MarkerInfoWindow p={locationListMap} />
      </div>
    </div>
  );
}
