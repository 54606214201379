import React from "react";
import LoginForm from "./components/loginForm";

export default function Index() {
  return (
    <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
      {/* begin::Signin */}
      <LoginForm />
      {/* end::Signin */}
    </div>
  );
}
