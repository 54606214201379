import React from "react";
import { Modal } from "react-bootstrap";
import { getYoutubeId } from "../../../../utils";
export default function Modals({ show, handleClose, varDetail }) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div className="var_detail_modal model-body p-2">
          <div className="modal_upper_section' ">
            <div className="modal_image_section">
              <img
                className="user-image"
                src={varDetail.userImage ?? "images/ic_var.png"}
                alt="user"
              />
              <div className="user-details">
                <div className="flex">
                  <span className="fw-bold ">{varDetail.fullName ?? ""}</span>
                </div>
              </div>
            </div>
            <div className="modal_information_section">
              <div className="user-details-t">
                <span className="flex-t">{varDetail.userName ?? ""}</span>
              </div>
              <div className="user-details-t">
                <span className="flex-t">{varDetail.emailId ?? ""}</span>
              </div>
              {/* <div className="user-details-t">
                          <span className="flex-call">{varDetail.contactNumber??""}</span>
                      </div> */}
            </div>
            <div
              className="modal_location_section"
              style={{
                textAlign: "right",
                float: "right",
                marginRight: "10px",
              }}
            >
              <div className="location">
                {varDetail.locationsList && varDetail.locationsList.length}
                Locations
              </div>
              <div className="location">
                {varDetail.locationBooked ?? 0} Booked
              </div>
            </div>
          </div>
          <div className="user-details-t">
            <div className="user-details-t my-2">
              <span className="flex-p">{varDetail.description ?? ""}</span>
            </div>
            <div className="user-details-t">
              <iframe
                width="100%"
                height="350px"
                style={{ borderRadius: "16px" }}
                src={
                  varDetail.youtubeLink
                    ? `https://www.youtube.com/embed/${getYoutubeId(
                        varDetail.youtubeLink
                      )}`
                    : "https://www.youtube.com/watch?v=jAMFX1VBJ9Y"
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>

              {/* <a
                  href={
                    varDetail.youtubeLink ??
                    "https://www.youtube.com/watch?v=jAMFX1VBJ9Y"
                  }
                  target="_blank"
                  className="flex-link"
                  rel="noreferrer"
                >
                  {varDetail.youtubeLink ??
                    "https://www.youtube.com/watch?v=jAMFX1VBJ9Y"}
                </a> */}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
